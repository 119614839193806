@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.cdnfonts.com/css/acumin-pro");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--body-bg-color);
  /* font-family: "Acumin Pro" !important; */
  font-family: "Acumin Pro", sans-serif;

  height: calc(100vh - 244px);
}

a {
  color: #435e9b;
  text-decoration: underline;
}

ul {
  margin-bottom: 0;
}

ul li {
  list-style: none;
}

h2 {
  color: var(--color-dark);
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  padding-top: 0;
  margin-bottom: 15px;
}

h3 {
  color: var(--color-dark);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg-none {
  background: none !important;
}

.blue-color {
  color: #435e9b;
}

:focus-visible {
  outline: none;
}

.container-fluid {
  max-width: 95%;
}

.py-120 {
  padding: 120px 0;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.py-100 {
  padding: 100px 0 !important;
}

.py-50 {
  padding: 50px 0 !important;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.bg-light {
  background: var(--bg-color-light);
}

:root {
  --body-bg-color: #edf2f6;
  --theme-color: #435e9b;
  --color-white: #ffffff;
  --color-dark: #000000;
  --color-gray: #545454;
  --bg-color-light: #ffffff;
  --bg-color-dark: #000000;
  --err-color-red: #f01e2c;
  --light-green-color: #b4e4ba;
  --light-red-color: #feb8c2;
  --light-yellow-color: #f6b100;
  --light-gray-color: #c5c5c5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: black !important;
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.error {
  color: var(--err-color-red);
}

.sucess {
  color: var(--light-green-color);
}

.danger {
  color: var(--light-red-color);
}

.yellow {
  color: var(--light-yellow-color);
}

.blue {
  color: var(--theme-color);
}

.gray {
  color: var(--light-gray-color);
}

.spid-btn:hover {
  cursor: pointer;
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  background: var(--body-bg-color);
}

.bottom_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/*----------------------------
 main header
-------------------------*/
header {
  background: var(--theme-color);
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  color: black;
  z-index: 1;
  transition: 0.4s ease-out;
}

.main-header a {
  text-decoration: none;
  text-transform: capitalize;
  color: var(--color-white);
}

.main-header .nav-links {
  display: flex;
  align-items: center;
  list-style: none;
}

.main-header .nav-links .nav-link {
  padding: 0.5rem 0.4rem;
}

.main-header .nav-links .nav-link a {
  margin: 0.2rem;
  font-size: 14px;
  font-weight: 100;
}

.main-header .nav-links .nav-link a.active {
  font-weight: bold;
}

.main-header .nav-links .nav-link a span {
  padding-right: 3px;
}

.main-header .nav-links .nav-link a i {
  font-size: 24px;
}

.main-header .menu-icon {
  position: relative;
  padding: 15px 0px;
  cursor: pointer;
  z-index: 1;
  display: none;
}

.main-header .menu-icon__line {
  display: block;
  position: relative;
  background: var(--bg-color-light);
  height: 3px;
  width: 25px;
  border-radius: 4px;
}

.main-header .menu-icon__line::before,
.main-header .menu-icon__line::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: var(--bg-color-light);
  transition: background 0.8s ease;
}

.main-header .menu-icon__line::before {
  transform: translateY(-7px);
}

.main-header .menu-icon__line::after {
  transform: translateY(7px);
}

.main-header .menu-btn {
  display: none;
}

.main-header.scrolled {
  height: 65px;
  color: white;
}

.main-header.scrolled .menu-icon__line,
.main-header.scrolled .menu-icon__line::before,
.main-header.scrolled .menu-icon__line::after {
  background: white;
}

@media screen and (max-width: 767px) {
  .container-fluid {
    max-width: 100%;
  }

  .title-que-label p {
    font-size: 18px !important;
  }

  .privacycheck.option-box.login_check_box .lns-checkbox span {
    padding-left: 58px;
    margin: 0;
    font-size: 14px;
    line-height: 15px !important;
    text-align: left;
  }

  .main-header .menu-icon {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .main-header .menu-icon__line {
    animation: closedMid 0.8s backwards;
    animation-direction: reverse;
  }

  .main-header .menu-icon__line::before {
    animation: closedTop 0.8s backwards;
    animation-direction: reverse;
  }

  .main-header .menu-icon__line::after {
    animation: closedBtm 0.8s backwards;
    animation-direction: reverse;
  }

  .main-header .nav-links {
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 0; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 0;
    width: 100vw;
    height: 100vh;
    font-size: 2rem;
    color: #fff;
    background: var(--theme-color);
    transition: opacity 0.4s 0.3s, clip-path 1s 0.3s;
    clip-path: circle(100px at top right);
    display: none;
    overflow-y: scroll;
  }

  .main-header .nav-links .nav-link {
    opacity: 1;
    transform: translateX(0);
    width: 100%;
    text-align: center;
    padding: 18px 0;
  }

  .main-header .nav-links .nav-link a {
    display: block;
    /* padding: 0 0; */
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    line-height: normal;
  }

  .main-header .nav-links .nav-link a.solid-btn {
    display: inline-block;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    padding: 16px 48px;
  }

  .main-header .nav-link.information-privacy a {
    font-family: Montserrat;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    text-decoration-line: underline;
  }

  .main-header .menu-btn:checked~.nav-links {
    /* opacity: 1; */
    clip-path: circle(100% at center);
    display: block;
  }

  .main-header .menu-btn~.nav-links .nav-link {
    display: block;
    padding: 18px 0;
  }

  /* 
  .main-header .menu-btn:checked ~ .nav-links .nav-link {
    display: none;
  }
 */

  .main-header .menu-btn:checked~.nav-links .nav-link {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.4s ease-in-out,
      transform 0.6s cubic-bezier(0.175, 0.085, 0.32, 1.275);
  }

  .main-header .menu-btn:checked~.nav-links .nav-link a i {
    display: none;
  }

  .main-header .menu-btn:checked~.nav-links .nav-link:nth-of-type(1) {
    transition-delay: 0.7s;
  }

  .main-header .menu-btn:checked~.nav-links .nav-link:nth-of-type(2) {
    transition-delay: 0.8s;
  }

  .main-header .menu-btn:checked~.nav-links .nav-link:nth-of-type(3) {
    transition-delay: 0.9s;
  }

  .main-header .menu-btn:checked~.nav-links .nav-link:nth-of-type(4) {
    transition-delay: 1s;
  }

  .main-header .menu-btn:checked~.menu-icon {
    border-radius: 50%;
    animation: pulse 1s;
  }

  /* .main-header .menu-btn:checked ~ .menu-icon .menu-icon__line {
    background: #fff;
    animation: openMid 0.8s forwards;
  }
  .main-header .menu-btn:checked ~ .menu-icon .menu-icon__line::before {
    background: white;
    animation: openTop 0.8s forwards;
  }
  .main-header .menu-btn:checked ~ .menu-icon .menu-icon__line::after {
    background: white;
    animation: openBtm 0.8s forwards;
  } */
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
  }

  to {
    box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
  }
}

@keyframes openTop {
  0% {
    transform: translateY(-5px) rotate(0deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

@keyframes closedTop {
  0% {
    transform: translateY(-5px) rotate(0deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

@keyframes openMid {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes closedMid {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes openBtm {
  0% {
    transform: translateY(5px) rotate(0deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

@keyframes closedBtm {
  0% {
    transform: translateY(5px) rotate(0deg);
  }

  50% {
    transform: translateY(0px) rotate(0deg);
  }

  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

.bottom-header {
  background: var(--theme-color);
  padding: 8px 0;
}

.bottom-header .page-breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 13px 0;
}

.bottom-header .page-breadcrumb li a {
  font-size: 15px;
  color: var(--color-white);
  letter-spacing: -0.27px;
  display: block;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  padding-right: 8px;
  padding-left: 7px;
}

.bottom-header .page-breadcrumb li a span {
  padding-left: 3px;
}

/*----------------------------
 end main header
-------------------------*/

/*------------------------
 option detail section
 ------------------------*/

.option-detail {
  padding: 50px 0;
  min-height: 100%;
  position: relative;
}

.left-side {
  text-align: center;
  border-radius: 20px;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.left-side h2 {
  padding-top: 24px;
}

button.solid-btn {
  border-radius: 4px;
  border: none;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Acumin Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 80.688%;
  padding: 16px 24px 15px 24px;
  text-decoration: none;
  display: inline-block;
  color: var(--color-dark);
}

button.solid-btn.bg-color {
  background: var(--theme-color);
  color: var(--color-white) !important;
}

.fade-btn {
  background: var(--INDACO-White, #fff);
  color: #b4b4b4 !important;
}

.option-box {
  border-radius: 8px;
  background: var(--INDACO-White, #fff);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 32px;
  margin: 40px 0px 24px 0px;
}

.option-box .radio {
  margin: 16px 0 0;
}

.option-box .radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.option-box label {
  display: inline-block;
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.option-box .radio input[type="radio"]+.radio-label:before {
  content: "";
  background: var(--bg-color-light);
  border-radius: 100%;
  border: 1px solid var(--bg-color-dark);
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;
  margin-right: 10px;
  vertical-align: text-bottom;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.option-box .radio input[type="radio"]:checked+.radio-label:before {
  background-color: var(--theme-color);
  box-shadow: inset 0 0 0 3px #f4f4f4;
}

.option-box .radio input[type="radio"]:focus+.radio-label:before {
  outline: none;
  border-color: var(--theme-color);
}

.option-box .radio input[type="radio"]:disabled+.radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.option-box .radio input[type="radio"]+.radio-label:empty:before {
  margin-right: 0;
}

.option-box select {
  border-radius: 8px;
  height: 28px;
  padding: 0px 5px;
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-right: 6px;
}

.option-box .lns-checkbox {
  display: inline-flex;
  font-size: 1rem;
  align-items: center;
  line-height: 1;
}

@media (min-width: 992px) {
  .option-box .lns-checkbox {
    font-size: 1.25rem;
  }
}

.option-box .lns-checkbox span {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.option-box .lns-checkbox input[type="checkbox"] {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.option-box .lns-checkbox input[type="checkbox"]:checked+span::after {
  background-color: var(--theme-color);
}

.option-box .lns-checkbox input[type="checkbox"]:checked+span {
  color: var(--color-dark);
}

.option-box .lns-checkbox input[type="checkbox"]:focus+span::before {
  border-color: var(--theme-color);
}

.option-box .lns-checkbox input[type="checkbox"]:disabled {
  opacity: 0.85;
}

.option-box .lns-checkbox input[type="checkbox"]:disabled+span {
  color: #9a9a88;
}

.option-box .lns-checkbox span::before {
  content: "";
  display: inline-block;
  border-radius: 8px;
  background-color: var(--bg-color-light);
  margin-right: 8px;
  height: 24px;
  width: 24px;
  border: 1px solid var(--bg-color-dark);
}

.option-box .lns-checkbox span::after {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  background-color: transparent;
  left: 4px;
  position: absolute;
}

/*-------------------
 meditation-yaga
 --------------------------*/

.meditation-yaga-img img {
  width: 100%;
}

.meditation-yaga-section h2 {
  /* color: var(--color-dark);
    text-align: center;
    font-family: 'Acumin Pro';
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-top: 0; */
  margin-bottom: 30px;
}

.meditation-yaga-section h3 {
  /* color: var(--color-dark);
    font-family: 'Acumin Pro';
    font-size: 24px;
    font-style: normal;
    font-weight: 700; */
  line-height: 22.827px;
  /* margin-bottom: 20px; */
}

/*------------------
Personal data
---------------------*/

.address-detail {
  max-width: 606px;
  margin: 0 auto;
  width: 100%;
}

.address-detail .option-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.address-detail .option-box h3 {
  margin-bottom: 0;
}

.address-detail a.edit {
  color: var(--theme-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.address-detail .form-control {
  background: none;
  border: 1px solid var(--color-dark);
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 8px 8px;
  color: var(--color-dark);
  border-radius: 8px;
  margin-top: 8px;
}

.login-field label {
  margin-top: 9px;
}

.address-detail .input-box .form-group {
  padding: 16px 24px;
  font-size: 14px;
}

.address-detail .input-box label {
  font-size: 16px;
  padding-bottom: 14px;
  line-height: 16px;
}

.address-detail .input-box {
  padding: 32px 8px 0 8px;
}

.form-control:focus {
  box-shadow: none;
}

.address-detail label {
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.827px;
  letter-spacing: -0.274px;
  color: var(--color-gray);
  margin-bottom: 3px;
}

.address-detail .form-group {
  border-radius: 8px;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  /* margin: 16px 0 0 0; */
}

/**16/01/2024***/

.right-side.address-detail.address-label {
  margin-top: 0;
}

.right-side.address-detail.address-label .form-group {
  font-size: 14px;
}

.right-side.address-detail.address-label h3 {
  font-size: 24px;
}

.address-detail.address-label p {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  color: var(--color-dark);
  margin-bottom: 0;
}

/* .right-side.address-detail.address-label .label-box {
  margin-top: 48px;
} */

/*-----------------
  Question answer
  --------------------*/

.question-answer h2 {
  text-align: left;
  margin-bottom: 27px;
}

.question-answer p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--color-dark);
}

.question-answer-slider .carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.question-answer-slider .carousel.animation-reveal {
  -webkit-animation: reveal 1s cubic-bezier(0.77, 0, 0.175, 1);
  animation: reveal 1s cubic-bezier(0.77, 0, 0.175, 1);
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.question-answer-slider .card {
  margin: 0 1rem;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 35, 0.25);
  border-radius: 8px;
  height: 200px;
  width: 200px;
  display: block;
  position: relative;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
  transition: width 0.16s ease-in-out, height 0.16s ease-in-out;
}

.question-answer-slider .card.is-custom-selected {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-answer-slider .card-bg {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.question-answer-slider .flickity-enabled {
  position: relative;
}

.question-answer-slider .flickity-enabled:focus {
  outline: none;
}

.question-answer-slider .flickity-viewport {
  position: relative;
  height: 100%;
}

.question-answer-slider .flickity-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

/*-----------------------
slider-section
---------------------------*/

.option-detail.slider-section {
  margin-top: 50px;
}

.slider-section .owl-carousel.owl-theme.owl-loaded.owl-drag {
  border-radius: 20px;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 48px 80px 30px 80px;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}

.slider-section .owl-nav span {
  font-size: 70px;
  font-weight: 300;
}

.slider-section .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #000 !important;
}

.slider-section .owl-nav .owl-prev span {
  position: absolute;
  left: 30px;
  top: 130px;
}

.slider-section .owl-nav .owl-next span {
  position: absolute;
  right: 30px;
  top: 130px;
}

.slider-section .card-block {
  display: flex;
  flex-wrap: wrap;
}

.slider-section .card-block h2 {
  text-align: left;
  margin-bottom: 20px;
}

.slider-section .content {
  padding: 0 0px 0 48px;
  text-align: justify;
  max-width: 570px;
}

.slider-section .content p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 0;
}

.slider-section .mynav {
  position: absolute;
  top: 20px;
  right: 0;
  display: inline-block;
  cursor: pointer;
}

.slider-section .mynav-prev,
.slider-section .mynav-next {
  position: relative;
  width: 25px;
  height: 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 50%;
  border-color: transparent;
  z-index: 9;
}

.slider-section .owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: -40px;
  position: relative;
  z-index: 9;
}

.slider-section .owl-carousel .owl-item img {
  width: fit-content;
}

.slider-section .owl-theme .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  color: #c5c5c5;
}

.slider-section .owl-theme .owl-dots .owl-dot.active span,
.slider-section .owl-theme .owl-dots .owl-dot:hover span {
  background: #b4e4ba;
}

.option-detail.mobile-section .card-block {
  justify-content: center;
  border-radius: 20px;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 48px 32px 32px 32px;
  max-width: 860px;
  margin: 0 auto;
  margin-bottom: 30px;
}

/*------------------
    login form
-------------------------*/

.login-form .forgot-pass {
  text-decoration: none;
  font-weight: 600;
  font-family: "Acumin Pro", sans-serif;
  color: var(--theme-color);
}

.address-detail .login-form label {
  font-family: "Acumin Pro", sans-serif;
}

/*-----------------------
 login page (28/12/2023)
 -----------------------------*/

.login_section p {
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-family: "Acumin Pro", sans-serif;
  color: var(--color-dark);
  margin-bottom: 0;
}

.option-box.login_check_box .lns-checkbox span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 45px;
  font-family: "Roboto", sans-serif;
  display: inline-flex;
  background: var(--bg-color-light);
  padding: 0 30px;
  margin: 8px 0px;
  padding: 8px 16px 8px 8px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 56px;
}

.option-box.login_check_box .lns-checkbox span::before {
  height: 40px;
  width: 40px;
  border: none;
  background: var(--body-bg-color);
}

.option-box.login_check_box .lns-checkbox span::after {
  height: 30px;
  width: 30px;
}

.login_section .entra_btn img {
  width: 235px;
}

.option-box.login_check_box .option-box {
  margin-bottom: 0;
}

.option-box.login_check_box .lns-checkbox input[type="checkbox"]:checked+span::after {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 20px;
  width: 13px;
  height: 21px;
  border: solid #b4e4ba;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border-radius: 3px;
  background: none;
}

.login_detail_nner p {
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-family: "Acumin VF", sans-serif;
  color: var(--color-dark);
  margin-bottom: 24px;
  text-align: justify;
}

.login_detail_nner h2 {
  text-align: left;
  margin-bottom: 30px;
}

.login_detail_nner {
  padding: 0 15px;
}

.login_detail_nner span {
  margin-bottom: 10px !important;
}

.option-box.login_check_box .lns-checkbox input:checked+span {
  color: var(--color-dark);
  border-radius: 4px;
  background: var(--INDACO-Verde, #b4e4ba);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

/*------------------
    Register form
-----------------------------*/

.register-form .form-check-label {
  color: var(--theme-color);
  text-decoration: none;
  font-family: "Acumin Pro", sans-serif;
}

.register-form .form-check-label a {
  text-decoration: none;
  font-weight: 600;
  color: var(--theme-color);
}

.register-form p {
  text-align: center;
}

.register-form p a {
  text-decoration: none;
  font-weight: 600;
  color: var(--theme-color);
}

/*-------------------------
 queation summary
 -----------------------------*/

.box_question {
  border-radius: 8px;
  background: var(--INDACO-White, #fff);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.circle-img {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 100px;
  border: 4px solid var(--light-green-color);
}

.circle-img.danger {
  border: 4px solid var(--light-red-color);
}

.circle-img.yellow {
  border: 4px dashed #ffed8e;
}

.circle-img.blue {
  border: 4px dashed var(--theme-color);
}

.circle-img.gray {
  border: 4px dashed var(--light-gray-color);
}

.circle-img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 68px;
  object-fit: initial;
  border-radius: 100px;
  padding: 0px;
  overflow: hidden;
}

.question_detail h3 {
  margin-bottom: 0;
}

.question_detail h4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

/*--------------------
  footer section 
--------------------------*/
.footer-section img {
  width: 100%;
}

.mobile {
  display: none;
}

/*-------------------------
  responsive start
------------------------------*/

@media screen and (max-width: 767px) {
  .py-100 {
    padding: 50px 0 !important;
  }

  .grazie-img {
    width: 180px !important;
    height: 180px !important;
  }

  .py-50 {
    padding: 25px 0 !important;
  }

  .mt-50 {
    margin-top: 25px;
  }

  .mb-50 {
    margin-bottom: 25px;
  }

  label.menu-icon a i {
    font-size: 26px;
  }

  .lock-img {
    color: #9a9a88;
  }

  .notifaction.mobile {
    position: absolute;
    right: 50px;
    top: 10px;
  }

  .notifaction.mobile a i {
    font-size: 28px;
  }

  .desktop {
    display: none;
  }

  li.mobile.m-logo a img {
    width: 232px;
    padding-bottom: 40px;
    margin: 0 auto;
  }

  .mobile {
    display: block !important;
  }

  .main-header {
    height: 65px;
  }

  .bottom-header .page-breadcrumb {
    padding: 5px 0;
  }

  .right-side {
    margin-top: 15px;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0;
    background: transparent;
    box-shadow: none;
    text-align: left;
    margin-top: 17px;
  }

  .left-side .one h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    padding-top: 10px;
    color: var(--color-dark);
    word-break: break-all;
  }

  .left-side .one h3 br {
    display: none;
  }

  .left-side img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .left-side h2 {
    font-size: 32px;
    line-height: 28px;
    padding-top: 0;
    text-align: left;
    margin-bottom: 0;
  }

  .option-detail {
    padding: 15px 0;
  }

  .option-box {
    padding: 24px;
  }

  .option-box .lns-checkbox span {
    line-height: 30px !important;
    align-items: start;
    padding-left: 35px;
  }

  .option-box .lns-checkbox span::before {
    position: absolute;
    left: 0px;
    top: 5px;
  }

  .option-box .lns-checkbox span::after {
    position: absolute;
    top: 9px;
  }

  .footer-section {
    position: sticky;
    bottom: 0;
  }

  .footer-section img {
    height: 12.3vh;
  }

  .btn-section.mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
  }

  .option-detail .column-r {
    flex-direction: column-reverse;
  }

  .meditation-yaga-section h2 {
    margin-bottom: 24px;
  }

  .meditation-yaga-section h2 br {
    display: none;
  }

  .meditation-yaga-img img {
    width: 247px;
    height: 100%;
  }

  .mobile.p-relative {
    position: relative;
    top: 0;
  }

  .address-detail .option-box {
    margin-bottom: 0;
    align-items: start;
  }

  /*------------
       28/12/2023
     -----------*/
  .login_section p {
    text-align: justify;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 332px;
    margin: 0 auto;
  }

  .option-box.login_check_box .lns-checkbox span {
    padding-left: 55px;
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 230px;
  }

  .option-box.login_check_box .lns-checkbox span::before {
    top: 8px;
    left: 8px;
  }

  .option-box.login_check_box .lns-checkbox span::after {
    top: 0px;
  }

  .option-box.login_check_box .lns-checkbox input[type="checkbox"]:checked+span::after {
    top: 15px;
  }

  .login_detail_nner h2 {
    font-size: 31px;
    margin-bottom: 15px;
  }

  /* .login_detail_nner p {
    line-height: 25px;
    margin-bottom: 15px;
  } */

  /*----------------
      29/12/2023
    ----------------------*/
  .option-detail.welcome_section {
    padding-top: 90px;
    padding-bottom: 40px;
  }

  .welcome_section .left-side {
    margin-top: 40px;
  }

  .question_detail h3 {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 22.827px;
    letter-spacing: -0.274px;
  }

  .box_question button {
    border-radius: 5px;
  }

  /***10/01/2024***/
  .option-box.login_check_box {
    padding: 0;
    margin-top: 65px;
  }

  .login_section .entra_btn img {
    width: 230px;
    height: 43px;
  }

  .entra_btn {
    margin-top: 44px;
  }

  /* .custom-model .login_detail_nner {
    padding: 15px 24px 24px;
  } */

  /* .custom-model .login_detail_nner p {
    line-height: 24px;
    margin-bottom: 24px;
  } */

  .custom-model .option-box.login_check_box {
    margin: 0;
    text-align: center;
  }

  .custom-model .option-box .lns-checkbox {
    margin-bottom: 0;
  }

  .meditation-yaga-section h3 {
    line-height: 32px;
  }

  .option-detail.welcome_section.fill-in-home .btn-section {
    margin-top: 61px;
  }

  .fill-in-home .meditation-yaga-img img {
    width: 260px;
  }

  .welcome_section.fill-in-home .left-side {
    margin-top: 50px;
  }

  .min-height {
    min-height: 78vh;
  }

  .main_question_box {
    padding: 0 4px;
  }

  .left-side-inner img.check_pregnance {
    width: 100px !important;
    height: 100px;
  }

  .left-side .left-side-inner {
    width: 120px;
    height: 120px;
    margin: initial;
    /* border: 1px solid #000; */
  }

  .left-side .left-side-inner ul {
    width: 120px;
    height: 120px;
    min-height: 100%;
  }

  /***1 step circle***/

  .left-side .left-side-inner ul li .c1-1 {
    width: 120px;
    height: 124px;
    right: 1px;
    top: -3px;
  }

  /***2 step circle***/

  .left-side .left-side-inner ul li .c2-1 {
    right: 1px;
    width: 61px;
    top: 1px;
    height: 115px;
  }

  .left-side .left-side-inner ul li .c2-2 {
    width: 61px;
    height: 115px;
    top: 2px;
    right: auto;
    left: -1px;
  }

  /***3 step circle***/

  .left-side .left-side-inner ul li .c3-1 {
    right: 3px;
    width: 59px;
    top: -2px;
    height: 89px;
  }

  .left-side .left-side-inner ul li .c3-2 {
    bottom: -17px;
    right: 33px;
    width: 59px;
    height: 89px;
  }

  .left-side .left-side-inner ul li .c3-3 {
    width: 59px;
    height: 89px;
    left: 0px;
    top: -3px;
  }

  /***4 step circle***/

  .left-side .left-side-inner ul li .c4-1 {
    width: 58px;
    right: 3px;
    top: 1px;
    height: 56px;
  }

  .left-side .left-side-inner ul li .c4-2 {
    width: 58px;
    right: 1px;
    top: 59px;
    height: 56px;
  }

  .left-side .left-side-inner ul li .c4-3 {
    width: 58px;
    right: auto;
    top: auto;
    height: 56px;
    left: 3px;
    bottom: 3px;
  }

  .left-side .left-side-inner ul li .c4-4 {
    width: 58px;
    height: 56px;
    right: auto;
    left: 1px;
    bottom: auto;
    top: 3px;
  }

  /***5 step circle***/
  .left-side .left-side-inner ul li .c5-1 {
    width: 56px;
    right: 5px;
    top: 0px;
    height: 42px;
  }

  .left-side .left-side-inner ul li .c5-2 {
    width: 56px;
    right: -13px;
    top: 52px;
    height: 42px;
  }

  .left-side .left-side-inner ul li .c5-3 {
    width: 56px;
    right: auto;
    top: auto;
    height: 42px;
    left: 33px;
    bottom: -7px;
  }

  .left-side .left-side-inner ul li .c5-4 {
    width: 56px;
    height: 42px;
    right: auto;
    left: -12px;
    bottom: auto;
    top: 54px;
  }

  .left-side .left-side-inner ul li .c5-5 {
    width: 56px;
    height: 42px;
    bottom: auto;
    left: 4px;
    top: 1px;
  }

  /***6 step circle***/

  .left-side .left-side-inner ul li .c6-1 {
    width: 48px;
    right: 13px;
    top: -1px;
    height: 33px;
  }

  .left-side .left-side-inner ul li .c6-2 {
    width: 48px;
    right: -13px;
    top: 42px;
    height: 33px;
  }

  .left-side .left-side-inner ul li .c6-3 {
    width: 48px;
    right: 11px;
    top: 86px;
    height: 33px;
  }

  .left-side .left-side-inner ul li .c6-4 {
    width: 48px;
    height: 33px;
    right: auto;
    left: 11px;
    bottom: 1px;
  }

  .left-side .left-side-inner ul li .c6-5 {
    width: 48px;
    height: 33px;
    bottom: auto;
    left: -15px;
    top: 43px;
  }

  .left-side .left-side-inner ul li .c6-6 {
    width: 48px;
    height: 33px;
    bottom: auto;
    top: -1px;
    left: 9px;
  }

  /***7 step circle***/
  .left-side .left-side-inner ul li .c7-1 {
    width: 46px;
    right: 17px;
    top: 0px;
    height: 25px;
  }

  .left-side .left-side-inner ul li .c7-2 {
    width: 46px;
    right: -11px;
    top: 35px;
    height: 24px;
  }

  .left-side .left-side-inner ul li .c7-3 {
    width: 46px;
    right: -2px;
    top: 78px;
    height: 24px;
  }

  .left-side .left-side-inner ul li .c7-4 {
    width: 46px;
    height: 24px;
    right: auto;
    left: 36px;
    bottom: -2px;
  }

  .left-side .left-side-inner ul li .c7-5 {
    width: 46px;
    height: 24px;
    bottom: 17px;
    left: -4px;
    top: auto;
  }

  .left-side .left-side-inner ul li .c7-6 {
    width: 46px;
    height: 24px;
    bottom: auto;
    top: 36px;
    left: -14px;
  }

  .left-side .left-side-inner ul li .c7-7 {
    width: 46px;
    height: 24px;
    top: 1px;
    left: 13px;
  }

  /***8 step circle***/
  .left-side .left-side-inner ul li .c8-1 {
    width: 40px;
    right: 21px;
    top: 0;
    height: 20px;
  }

  .left-side .left-side-inner ul li .c8-2 {
    width: 40px;
    right: -7px;
    top: 28px;
    height: 20px;
  }

  .left-side .left-side-inner ul li .c8-3 {
    width: 40px;
    right: -8px;
    top: 68px;
    height: 20px;
  }

  .left-side .left-side-inner ul li .c8-4 {
    width: 40px;
    height: 20px;
    right: 20px;
    top: auto;
    bottom: 4px;
  }

  .left-side .left-side-inner ul li .c8-5 {
    width: 40px;
    height: 20px;
    bottom: 4px;
    left: 21px;
  }

  .left-side .left-side-inner ul li .c8-6 {
    width: 40px;
    height: 20px;
    bottom: 32px;
    left: -7px;
  }

  .left-side .left-side-inner ul li .c8-7 {
    width: 40px;
    height: 20px;
    top: 28px;
    left: -8px;
  }

  .left-side .left-side-inner ul li .c8-8 {
    width: 40px;
    height: 20px;
    top: 0px;
    left: 20px;
  }

  /***9 step circle***/
  .left-side .left-side-inner ul li .c9-1 {
    width: 36px;
    right: 24px;
    top: 1px;
    height: 22px;
  }

  .left-side .left-side-inner ul li .c9-2 {
    width: 36px;
    right: -2px;
    top: 24px;
    height: 22px;
  }

  .left-side .left-side-inner ul li .c9-3 {
    width: 36px;
    right: -7px;
    top: 58px;
    height: 22px;
  }

  .left-side .left-side-inner ul li .c9-4 {
    width: 36px;
    height: 22px;
    right: 11px;
    top: auto;
    bottom: 10px;
  }

  .left-side .left-side-inner ul li .c9-5 {
    width: 36px;
    height: 22px;
    bottom: -1px;
    left: auto;
    right: 43px;
  }

  .left-side .left-side-inner ul li .c9-6 {
    width: 36px;
    height: 22px;
    bottom: 11px;
    left: 9px;
  }

  .left-side .left-side-inner ul li .c9-7 {
    width: 36px;
    height: 22px;
    top: auto;
    bottom: 41px;
    left: -8px;
  }

  .left-side .left-side-inner ul li .c9-8 {
    width: 36px;
    height: 22px;
    top: 23px;
    left: -2px;
    bottom: auto;
  }

  .left-side .left-side-inner ul li .c9-9 {
    width: 36px;
    height: 22px;
    top: 1px;
    left: 25px;
    bottom: auto;
  }

  /***10 step circle***/

  .left-side .left-side-inner ul li .c10-1 {
    width: 34px;
    right: 30px;
    height: 16px;
    top: -1px;
  }

  .left-side .left-side-inner ul li .c10-2 {
    width: 34px;
    right: 3px;
    height: 16px;
    top: 19px;
  }

  .left-side .left-side-inner ul li .c10-3 {
    width: 34px;
    height: 16px;
    right: -7px;
    top: 50px;
  }

  .left-side .left-side-inner ul li .c10-4 {
    width: 34px;
    height: 16px;
    right: 3px;
    bottom: 23px;
  }

  .left-side .left-side-inner ul li .c10-5 {
    width: 34px;
    height: 16px;
    right: 29px;
    bottom: 3px;
  }

  .left-side .left-side-inner ul li .c10-6 {
    width: 34px;
    height: 16px;
    top: auto;
    bottom: 3px;
    left: 24px;
  }

  .left-side .left-side-inner ul li .c10-7 {
    width: 34px;
    height: 16px;
    right: 0;
    top: auto;
    bottom: 22px;
    left: -3px;
  }

  .left-side .left-side-inner ul li .c10-8 {
    width: 34px;
    height: 16px;
    bottom: 53px;
    left: -13px;
  }

  .left-side .left-side-inner ul li .c10-9 {
    width: 34px;
    height: 16px;
    right: 0;
    top: 20px;
    bottom: auto;
    left: -3px;
  }

  .left-side .left-side-inner ul li .c10-10 {
    width: 34px;
    height: 16px;
    right: 0;
    top: 0px;
    left: 23px;
  }

  .from-completed-screen .meditation-yaga-img img {
    width: 240px;
  }

  .from-completed-screen .welcome_section .left-side {
    margin-top: 44px;
  }

  .fcs-btn.mobile {
    margin-top: 66px;
    margin-bottom: 51px;
  }

  /***11/01/2024***/

  .customformcss .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color) !important;
    width: 24px;
    height: 24px;
  }

  .customformcss .form-check .form-check-input {
    margin-left: -33px !important;
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    margin-top: 3px;
  }

  .customformcss .form-group {
    padding: 16px 24px !important;
    margin-bottom: 20px;
  }

  .customformcss .form-group .col-form-label {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 0 12px 0;
  }

  .customformcss .form-check-label span {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .customformcss .form-check {
    padding-bottom: 10px;
    margin-bottom: 0;
    padding-left: 35px;
  }

  .customformcss .choices.form-group.formio-choices {
    padding: 0 !important;
    margin-bottom: 5px;
  }

  ul.page-breadcrumb.language-selection {
    justify-content: end;
    padding-top: 0;
    padding-left: 0;
  }

  .page-breadcrumb.language-selection li span.active {
    text-decoration: underline;
  }

  .bottom-header .page-breadcrumb.language-selection li span span {
    padding-left: 0px;
    position: relative;
    right: 4px;
  }

  .formintro .meditation-yaga-img img {
    width: 253px;
  }

  .formintro .left-side {
    margin-top: 0;
  }

  .option-detail.login_section.formintro {
    padding: 32px 0 !important;
  }

  .login_section.formintro p {
    max-width: 364px;
  }

  .formintro .right-side {
    margin-top: 0;
    padding-top: 32px;
  }

  .formintro .meditation-yaga-section h2 {
    margin-bottom: 32px;
  }

  h2 {
    font-size: 34px;
    line-height: 34px;
  }

  h3 {
    font-size: 22px;
  }

  .formio-component .formio-form .table tr {
    display: grid;
  }

  .formio-component .formio-form .table tr td {
    padding: 0;
    border: none;
  }

  /***12/01/2024**/
  .option-detail.slider-section.mobile-section .content {
    padding: 0;
  }

  .mobile-section.slider-section .card-block h2 {
    text-align: center;
    margin-bottom: 0;
    padding: 15px 0 25px;
    font-size: 34px;
    line-height: 40px;
  }

  .mobile-section.slider-section .content p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
  }

  .option-detail.slider-section.mobile-section {
    margin-top: 12px;
    padding: 24px 6px;
  }

  header.header-light {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    background: var(--bg-color-light);
    height: 60px;
    padding: 0 6px;
  }

  header.header-light .hamburger-react {
    width: 41px !important;
  }

  header.header-light .hamburger-react {
    color: #435e9b !important;
  }

  .color-white {
    background: #edf2f6 !important;
  }

  .color-white li a {
    color: #435e9b !important;
    font-size: 24px !important;
    line-height: normal;
  }

  .color-white .page-breadcrumb li span {
    color: #435e9b !important;
    font-size: 24px !important;
  }

  .main-header .color-white .nav-link.information-privacy a {
    color: #435e9b !important;
    font-size: 16px !important;
    font-family: "Acumin Pro", sans-serif;
  }

  button.btn.btn-primary {
    border-radius: 4px;
    background: var(--INDACO-White, #fff);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border: none;
    padding: 16px 24px;
    color: #000;
  }

  .signUpBtn {
    padding-top: 140px;
    text-align: center;
  }

  .signUpBtn button.btn.btn-primary {
    border-radius: 4px;
    background: var(--INDACO-White, #fff);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    border: none;
    padding: 16px 24px;
    color: #000;
    font-family: "Acumin Pro", sans-serif;
    line-height: normal;
  }

  header.header-blue {
    box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.2);
  }

  .language-selection li span {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .personal-detail .container {
    padding: 0;
  }

  .personal-detail .container .input-box {
    padding: 0;
  }

  .option-detail.personal-detail {
    padding: 32px 16px;
  }

  .option-detail .input-box label {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
    margin-top: 5px;
    padding: 0px;
  }

  .personal-detail .option-box h3 {
    padding-bottom: 16px;
  }

  .personal-detail .dropdown {
    font-size: 18px;
    font-weight: 700;
    border: 1px solid black;
    border-radius: 8px;
    padding-left: 10px;
  }

  .bottom-header {
    position: sticky;
    top: 65px;
    z-index: 9;
  }

  /***4/04/2024***/
  .formio-component-dataDiNascita p strong {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }

  .formio-component-datiDiContatto p strong {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }

  .formio-component-datiDiContatto1 p strong {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }

  .formio-component-datiDiContatto1 p {
    margin-bottom: 4px;
  }

  .formio-component-indicareLindirizzoDiDomicilio .card-header {
    background-color: transparent;
  }

  .formio-component-puoiIndicareLindirizzoDoveHaiAbitatoPrimaDiQuelloAttuale .card-header {
    background-color: transparent;
  }

  .test-01 .col {
    width: 31%;
    padding: 0px;
  }

  .Qual-question {
    margin-bottom: 20px !important;
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .non-so-ricordo {
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .customformcss .form-check-input:checked[type="checkbox"] {
    background-size: 22px;
    background-image: url("../images/checkboxtick.png");
  }

  /**18/04/2024*/

  .privacy-section .option-box.login_check_box {
    margin: 0;
    padding: 40px 0;
  }

  .privacy-section .option-box .lns-checkbox {
    margin-bottom: 16px;
  }

  .privacy-section .option-box .checkbox:last-child .lns-checkbox {
    margin-bottom: 0;
  }

  .privacy-section .meditation-yaga-img img {
    width: 300px !important;
    height: 300px !important;
    padding: 41.1px 19.135px 0px 18.9px;
  }

  .privacy-section .right-side {
    margin-top: 0;
  }

  .privacy-section {
    padding-top: 0 !important;
    padding-bottom: 36px !important;
  }

  .privacy-section .left-side {
    margin-top: 0;
  }

  .account_confermato_section .address-detail .input-box {
    padding: 0;
  }

  .account_confermato_section .meditation-yaga-section p {
    padding-bottom: 0;
  }

  .account_confermato_section .address-detail .input-box .form-group {
    margin-top: 40px !important;
  }

  .verifica-account {
    padding: 202px 16px 180px 16px !important;
  }

  .Ottimo_section .meditation-yaga-img img {
    width: 300px;
    height: 300px;
    padding: 0 83.156px 16.749px 84.154px;
  }

  .abbiamo_section .meditation-yaga-img img {
    width: 250px;
    height: 226px;
    padding: 0;
  }

  .abbiamo_section .meditation-yaga-img {
    padding: 37px;
  }

  .abbiamo_section {
    padding: 68px 16px 40px 16px !important;
  }

  .upload-motherId-section .id-upoload img {
    width: 150.002px;
    height: 150.002px;
  }

  .upload-motherId-section .left-side {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .upload-motherId-section .id-upoload .right-side {
    margin-top: 0px;
  }

  .upload-motherId-section {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .upload-motherId-section .right-side.address-detail {
    margin-top: 40px;
  }

  .upload-motherId-section .image-upload-wrap {
    margin-top: 16px !important;
  }

  .registration_complete_section .meditation-yaga-img img {
    width: 221px;
    height: 221px;
    padding: 0;
    object-fit: contain;
    padding-bottom: 24px;
  }

  .registration_complete_section {
    padding: 60px 16px 94px 16px !important;
  }

  .registration_complete_section p {
    padding-bottom: 40px !important;
  }

  .documenti-content {
    padding-top: 40px;
  }

  .documenti-content p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    padding-bottom: 0;
    color: #435e9b !important;
  }
}

@media screen and (max-width: 991px) {
  .py-50 {
    padding: 25px 0 !important;
  }

  .mt-50 {
    margin-top: 25px;
  }

  .option-detail .column-r {
    flex-direction: column-reverse;
  }

  .meditation-yaga-section h2 br {
    display: none;
  }

  .meditation-yaga-img img {
    width: 247px;
    height: 100%;
  }

  .mobile.p-relative {
    position: relative;
    top: 0;
  }

  /*----------------
        28/12/2023
    ---------------*/
  .login_section p {
    text-align: justify;
  }

  /*----------------
          29/12/2023
    ----------------------*/
  .option-detail.welcome_section {
    padding-top: 45px;
    padding-bottom: 40px;
  }

  .welcome_section .left-side {
    margin-top: 40px;
  }

  .comple-section .left-side {
    margin-top: 20px;
  }

  .question_detail h3 {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.274px;
  }
}

button.icons-btn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
}

.question_action button {
  border: none;
}

.form-group {
  border-radius: 5px;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  font-size: 20px;
}

.form-group .col-form-label {
  font-weight: 600;
}

.formio-component-button {
  box-shadow: none;
  text-align: center;
  background: none;
}

.formio-component-button button {
  background-color: #435e9b !important;
  border-color: #435e9b !important;
}

.left-side-inner {
  position: relative;
  width: 270px;
  height: 270px;
  margin: 0 auto;
}

.left-side-inner ul {
  position: relative;
  padding-left: 0;
  min-height: 270px;
}

/*------------------------------
  circle 
 ------------------------------*/

.left-side-inner {
  position: relative;
  width: 270px;
  height: 270px;
  margin: 0 auto;
}

.left-side-inner ul {
  position: relative;
  padding-left: 0;
  min-height: 270px;
}

.left-side-inner ul li {
  cursor: pointer;
}

.left-side-inner img.check_pregnance {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
}

/***1 step circle***/

.left-side-inner ul li .c1-1 {
  position: absolute;
  width: 100%;
  left: auto;
  right: 4px;
  top: 4px;
}

/***2 step circle***/

.left-side-inner ul li .c2-1 {
  position: absolute;
  right: 3px;
  width: 50%;
  top: 10px;
}

.left-side-inner ul li .c2-2 {
  position: absolute;
  left: 3px;
  width: 50%;
  transform: rotate(180deg);
  top: 11px;
}

/***3 step circle***/

.left-side-inner ul li .c3-1 {
  position: absolute;
  right: 18px;
  width: 46%;
  transform: rotate(0deg);
  top: 12px;
}

.left-side-inner ul li .c3-2 {
  position: absolute;
  bottom: -23px;
  right: 78px;
  width: 46%;
  transform: rotate(120deg);
}

.left-side-inner ul li .c3-3 {
  position: absolute;
  left: 9px;
  top: 10px;
  width: 46%;
  transform: rotate(240deg);
}

/***4 step circle***/

.left-side-inner ul li .c4-1 {
  position: absolute;
  right: 23px;
  width: 44%;
  top: 13px;
  transform: rotate(0deg);
}

.left-side-inner ul li .c4-2 {
  position: absolute;
  bottom: 23px;
  right: 19px;
  width: 44%;
  transform: rotate(90deg);
}

.left-side-inner ul li .c4-3 {
  position: absolute;
  bottom: 19px;
  left: 12px;
  width: 44%;
  transform: rotate(180deg);
}

.left-side-inner ul li .c4-4 {
  position: absolute;
  left: 8px;
  top: 17px;
  width: 44%;
  transform: rotate(270deg);
}

/***5 step circle***/

.left-side-inner ul li .c5-1 {
  position: absolute;
  width: 42%;
  right: 27px;
  top: 18px;
  transform: rotate(0deg);
}

.left-side-inner ul li .c5-2 {
  position: absolute;
  width: 42%;
  right: -11px;
  transform: rotate(72deg);
  top: 122px;
}

.left-side-inner ul li .c5-3 {
  position: absolute;
  bottom: -4px;
  width: 42%;
  left: 78px;
  transform: rotate(144deg);
}

.left-side-inner ul li .c5-4 {
  position: absolute;
  left: -13px;
  width: 42%;
  transform: rotate(216deg);
  top: 127px;
}

.left-side-inner ul li .c5-5 {
  position: absolute;
  left: 19px;
  width: 42%;
  top: 20px;
  transform: rotate(288deg);
}

/***6 step circle***/

.left-side-inner ul li .c6-1 {
  position: absolute;
  width: 38%;
  right: 39px;
  top: 14px;
}

.left-side-inner ul li .c6-2 {
  position: absolute;
  width: 38%;
  right: -11px;
  top: 99px;
  transform: rotate(60deg);
}

.left-side-inner ul li .c6-3 {
  position: absolute;
  width: 38%;
  bottom: 16px;
  right: 38px;
  transform: rotate(120deg);
}

.left-side-inner ul li .c6-4 {
  position: absolute;
  bottom: 14px;
  left: 30px;
  width: 38%;
  transform: rotate(180deg);
}

.left-side-inner ul li .c6-5 {
  transform: rotate(240deg);
  width: 38%;
  position: absolute;
  left: -20px;
  bottom: 99px;
}

.left-side-inner ul li .c6-6 {
  position: absolute;
  left: 29px;
  width: 38%;
  top: 16px;
  transform: rotate(300deg);
}

/***7 step circle***/
.left-side-inner ul li .c7-1 {
  position: absolute;
  width: 35%;
  right: 47px;
  top: 17px;
}

.left-side-inner ul li .c7-2 {
  position: absolute;
  width: 35%;
  right: 39px;
  top: 87px;
  right: -10px;
  transform: rotate(51.42deg);
}

.left-side-inner ul li .c7-3 {
  position: absolute;
  width: 35%;
  right: 9px;
  top: auto;
  bottom: 38px;
  transform: rotate(102.84deg);
}

.left-side-inner ul li .c7-4 {
  position: absolute;
  width: 35%;
  right: 90px;
  top: auto;
  bottom: -3px;
  left: auto;
  transform: rotate(154.26deg);
}

.left-side-inner ul li .c7-5 {
  position: absolute;
  width: 35%;
  right: 0;
  top: auto;
  bottom: 36px;
  left: 3px;
  transform: rotate(205.68deg);
}

.left-side-inner ul li .c7-6 {
  position: absolute;
  width: 35%;
  right: 0;
  top: 90px;
  left: -19px;
  transform: rotate(257.1deg);
}

.left-side-inner ul li .c7-7 {
  position: absolute;
  width: 35%;
  right: 0;
  top: 18px;
  left: 37px;
  transform: rotate(308.52deg);
}

/***8 step circle***/
.left-side-inner ul li .c8-1 {
  position: absolute;
  width: 32%;
  right: 51px;
  top: 20px;
}

.left-side-inner ul li .c8-2 {
  position: absolute;
  width: 32%;
  right: -9px;
  top: 79px;
  transform: rotate(45deg);
}

.left-side-inner ul li .c8-3 {
  position: absolute;
  width: 32%;
  right: -9px;
  top: 162px;
  transform: rotate(90deg);
}

.left-side-inner ul li .c8-4 {
  position: absolute;
  width: 32%;
  right: 50px;
  top: auto;
  transform: rotate(135deg);
  bottom: 2px;
}

.left-side-inner ul li .c8-5 {
  position: absolute;
  width: 32%;
  right: auto;
  top: auto;
  transform: rotate(180deg);
  bottom: 2px;
  left: 49px;
}

.left-side-inner ul li .c8-6 {
  position: absolute;
  width: 32%;
  right: auto;
  top: auto;
  transform: rotate(225deg);
  bottom: 61px;
  left: -10px;
}

.left-side-inner ul li .c8-7 {
  position: absolute;
  width: 32%;
  right: auto;
  top: 79px;
  transform: rotate(270deg);
  left: -10px;
}

.left-side-inner ul li .c8-8 {
  position: absolute;
  width: 32%;
  right: auto;
  top: 20px;
  transform: rotate(315deg);
  left: 49px;
}

/***9 step circle***/
.left-side-inner ul li .c9-1 {
  position: absolute;
  width: 29%;
  right: 61px;
  top: 17px;
}

.left-side-inner ul li .c9-2 {
  position: absolute;
  width: 29%;
  right: 4px;
  top: 65px;
  transform: rotate(40deg);
}

.left-side-inner ul li .c9-3 {
  position: absolute;
  width: 29%;
  right: -8px;
  top: auto;
  transform: rotate(80deg);
  bottom: 86px;
}

.left-side-inner ul li .c9-4 {
  position: absolute;
  width: 29%;
  right: 29px;
  top: auto;
  transform: rotate(120deg);
  bottom: 23px;
}

.left-side-inner ul li .c9-5 {
  position: absolute;
  width: 29%;
  right: auto;
  top: auto;
  transform: rotate(160deg);
  bottom: -2px;
  left: 93px;
}

.left-side-inner ul li .c9-6 {
  position: absolute;
  width: 29%;
  right: auto;
  top: auto;
  transform: rotate(200deg);
  bottom: 24px;
  left: 24px;
}

.left-side-inner ul li .c9-7 {
  position: absolute;
  width: 29%;
  right: auto;
  top: auto;
  transform: rotate(240deg);
  bottom: 88px;
  left: -13px;
}

.left-side-inner ul li .c9-8 {
  position: absolute;
  width: 29%;
  right: auto;
  top: 63px;
  transform: rotate(280deg);
  bottom: auto;
  left: 0px;
}

.left-side-inner ul li .c9-9 {
  position: absolute;
  width: 29%;
  top: 16px;
  transform: rotate(320deg);
  bottom: auto;
  left: 57px;
}

/***10 step circle***/
.left-side-inner ul li .c10-1 {
  position: absolute;
  width: 26%;
  right: 70px;
  top: 18px;
}

.left-side-inner ul li .c10-2 {
  position: absolute;
  width: 26%;
  right: 16px;
  top: 57px;
  transform: rotate(36deg);
}

.left-side-inner ul li .c10-3 {
  position: absolute;
  width: 26%;
  right: -4px;
  top: 120px;
  transform: rotate(72deg);
}

.left-side-inner ul li .c10-4 {
  position: absolute;
  width: 26%;
  right: 17px;
  top: auto;
  transform: rotate(108deg);
  bottom: 49px;
}

.left-side-inner ul li .c10-5 {
  position: absolute;
  width: 26%;
  right: 71px;
  top: auto;
  transform: rotate(144deg);
  bottom: 10px;
  left: auto;
}

.left-side-inner ul li .c10-6 {
  position: absolute;
  width: 26%;
  right: 60px;
  top: auto;
  transform: rotate(180deg);
  bottom: 10px;
  left: 61px;
}

.left-side-inner ul li .c10-7 {
  position: absolute;
  width: 26%;
  right: 56px;
  top: auto;
  transform: rotate(216deg);
  bottom: 49px;
  left: 7px;
}

.left-side-inner ul li .c10-8 {
  position: absolute;
  width: 26%;
  right: auto;
  top: auto;
  transform: rotate(252deg);
  bottom: 112px;
  left: -13px;
}

.left-side-inner ul li .c10-9 {
  position: absolute;
  width: 26%;
  right: 0;
  top: 57px;
  transform: rotate(288deg);
  bottom: auto;
  left: 8px;
}

.left-side-inner ul li .c10-10 {
  position: absolute;
  width: 26%;
  right: 0;
  top: 18px;
  transform: rotate(324deg);
  left: 62px;
}

.disable-pointer {
  cursor: not-allowed;
}

.option-detail .back-btn {
  margin-right: 55px;
  margin-bottom: 25px;
  text-align: right !important;
}

.formio-component .formio-form .formio-component-submit button {
  border-radius: 80px;
  border: none;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Acumin Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 78.688%;
  padding: 15px 30px;
  text-decoration: none;
  display: inline-block;
  background: var(--theme-color);
  color: var(--color-white) !important;
}

.langChange {
  display: flex;
  align-items: center;
}

.langChange label {
  margin-right: 4px;
  color: white;
  align-items: center;
}

.form-check .form-check-input {
  margin-left: -30px !important;
}

.bottom-header .container-fluid .page-breadcrumb ul li a.active {
  font-weight: 600;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.maindiv {
  display: flex;
  align-items: center;
}

.status {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50px;
  margin-right: 10px;
}

.cat-name {
  margin-right: 10px;
}

/* .btn-edit {
  margin-right: 10px;
} */

/***23/06/2022***/

.position-relative {
  position: sticky !important;
  top: 0;
  z-index: 9;
}

.footer {
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.position-relative.header-section {
  z-index: 999;
}

body>iframe {
  display: none !important;
}

.mainCard {
  background-color: #f0f0f0;
  padding: 20px;
  margin: 10px;
  border-radius: 15px;
}

.addInput,
.removeCard,
.addMore,
.removeInput {
  margin: 10px;
  border-radius: 3px;
  padding: 5px 15px;
  border: none;
}

.removeInput {
  margin-top: 18%;
}

@media only screen and (max-width: 767px) {
  .removeInput {
    margin-top: 55%;
  }
}

@media only screen and (min-width: 992px) {
  .removeInput {
    margin-top: 55%;
  }
}

@media only screen and (min-width: 1200px) {
  .removeInput {
    margin-top: 18%;
  }
}

.error {
  color: red !important;
  text-align: end;
  font-size: small;
  margin-bottom: -10px;
  position: relative;
  bottom: 10px;
  margin-top: 10px;
}

.formio-errors {
  color: red !important;
  text-align: end;
  font-size: small;
  margin-bottom: -10px;
  position: relative;
  top: 3px;
}

.error-content {
  border: 1px solid red;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.verifica-account .error {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  position: relative;
  bottom: 0;
  text-align: center;
  padding-top: 24px;
}

.filterDate {
  margin-left: 25px !important;
}

.startEndDateFilter {
  border-radius: 10px;
  border: 1px solid whitesmoke;
  width: 145px;
  text-align: center;
  margin-left: 5px;
}

.clearBtn {
  background: whitesmoke;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  margin-left: 7px;
}

.summaryTable {
  font-size: 17px;
  float: right;
  margin-right: 35px !important;
  margin: 15px;
}

.summaryTable h3 {
  margin-left: 10px;
  margin-bottom: 15px;
  color: #faa148;
}

.cardBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 25%;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.cardBox:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.containerCard {
  padding: 2px 16px;
  margin: 35px;
  text-align: center;
}

.containerCard h4 {
  color: #435e9b;
  font-size: 18px;
}

.containerCard p {
  margin-top: 15px;
  font-size: 30px;
}

.boxContent {
  display: flex;
}

.inputBoxForMinValue {
  border: none;
  background: none;
  text-align: center;
}

.page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.page404 button {
  color: #fff;
  background: var(--theme-color);
  border: none;
}

.page404 button:hover {
  background: var(--theme-color);
}

.form-check-input:focus {
  box-shadow: none;
}

.formio-component .formio-form .formio-component-submit button {
  border-radius: 4px;
  border: none;
  background: var(--bg-color-light) !important;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Acumin Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 78.688%;
  padding: 16px 24px;
  text-decoration: none;
  display: inline-block;
  color: var(--color-dark) !important;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  /* z-index: 9; */
  margin: 0 auto;
  bottom: auto;
  margin-top: 18px !important;
  max-width: fit-content;

}

.next-custom-button {
  margin-bottom: 85px !important;
}

/* .closeToggle ul.nav-links {
  display: none !important;
} */

.opacityFixed {
  opacity: 1 !important;
}

.questionName a {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

#dropdown-date {
  display: flex;
}

#dropdown-day,
#dropdown-month {
  margin-right: 10px;
}

.test {
  padding: 100px;
}

.dropdown {
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid black;
}

.option {
  margin-left: 10px;
  color: var(--color-dark);
}

select {
  border-radius: 3px;
  padding-right: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>") no-repeat;
  background-position: right 5px top 50%;
}

#day,
#month {
  margin-right: 10px;
}

.main-accordation .accordion {
  padding-top: 12px;
}

.main-accordation .accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
  background-color: var(--bg-color-light);
  border-radius: 8px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  padding: 16px 24px;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease;
  border: none;
}

.main-accordation .accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  color: var(--color-dark);
  border: none;
  background: none;
  outline: none;
  margin: 0 20px 0 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 8px 0;
}

.main-accordation .accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.main-accordation .accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 17px;
  right: -23px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  left: auto;
}

.main-accordation .accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: -18px;
  left: -30px;
  width: 30px;
  height: 30px;
  background-image: url(../images/downarrow.png);
}

.main-accordation .accordion button[aria-expanded="true"] .icon::after {
  width: 30px;
  height: 30px;
  background-image: url(../images/cross.png);
}

.main-accordation .accordion button[aria-expanded="true"]+.accordion-content {
  opacity: 1;
  max-height: 50em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.main-accordation .accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.main-accordation .accordion .accordion-content p {
  margin: 14px 0 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.other-question {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: #fff;
  text-align: center;
  padding: 24px 12px;
  margin-top: 40px;
}

.other-question h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 0;
  padding-bottom: 24px;
}

.hamburger-react {
  z-index: 1 !important;
  height: 52px !important;
  width: 36px !important;
  color: #edf2f6 !important;
}

.hamburger-react div {
  border-radius: 5px;
  width: 32px !important;
  height: 4px !important;
}

/*---------------------
  contact page
----------------------*/
.main-contact {
  margin: 30px;
}

.contact-detail {
  padding: 16px 24px;
  border-radius: 8px;
  background: var(--INDACO-White, #fff);
  margin-bottom: 16px;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}

.contact-detail h4 {
  color: var(--INDACO-Nero, #000);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.827px;
  margin-bottom: 22px;
}

.contact-detail h5 {
  color: var(--INDACO-Nero, #000);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
  /* 75% */
}

.contact-detail h6 {
  color: var(--INDACO-Nero, #000);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

.contact-detail-email h4 {
  color: var(--INDACO-Nero, #000);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 24px 24px 8px;
  /* 95.111% */
}

.contact-img {
  padding: 24px 0;
}

.contact-img img {
  margin: 0 auto;
  display: block;
}

.prossima {
  margin: 0 auto;
  /* display: block; */
  text-align: center;
  margin-bottom: 30px !important;
}

.empty-prossima {
  margin-bottom: 70px
}

.prossima .solid-btn {
  width: fit-content;
  padding: 16px 8px 16px 24px;
}

/**05/02/2024/1.05**/

.formio-component .card {
  background: none !important;
  border: none !important;
  box-shadow: none;
}

.formio-component .card .card-body {
  padding: 15px 0;
  padding-top: 0px;
}

.formio-component .card .card-body .form-group {
  border-radius: 8px;
  background: var(--bg-color-light);
  box-shadow: none !important;
  padding: 16px 24px !important;
  margin: 16px 0 0 0;
}

.formio-component .card .card-body .formio-choices {
  background: transparent;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
  border-radius: 0;
  margin-top: 0;
}

.formio-component .dropdown {
  font-size: 20px;
  font-weight: 700;
  border: 1px solid black;
  background: none;
  border: 1px solid var(--color-dark);
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 8px 8px;
  color: var(--color-dark);
  border-radius: 8px;
}

.formio-component label {
  display: inline-block;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0;
  color: #000;
  padding-bottom: 14px;
  line-height: 28px;
}

.formio-component .has-feedback .form-control {
  padding-right: 10px;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid black;
  /* background: none; */
  border: 1px solid var(--color-dark);
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 8px 8px;
  color: var(--color-dark);
  border-radius: 8px;
}

.formio-component .card-header {
  color: var(--color-dark);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  background: transparent;
  margin-bottom: 0;
  border: none;
  margin-top: 20px;
  padding-bottom: 0px;
  margin-bottom: -5px;
}

.formio-component .row {
  gap: 10px;
}

.logoImg {
  display: flex;
  width: 80px;
  height: 80px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 4px solid var(--INDACO-Grigio-02, #c5c5c5);
  background: var(--INDACO-Grigio-02, #c5c5c5);
}

.greyImg {
  border: none !important;
}

.successHead {
  color: var(--INDACO-Verde, #b4e4ba);
}

.blockColour {
  color: #c5c5c5 !important;
}

.expiredColour {
  color: #eb3b55 !important;
}

/***8/02/2024***/

#notificationContainer {
  overflow: visible;
  position: absolute;
  top: 64px;
  margin-left: 0;
  width: 100%;
  z-index: -1;
  display: none;
  left: 0;
  right: 0;
  background: #edf2f6;
  min-height: 91vh;
  padding: 24px 24px 0 24px;
}

/* Popup Arrow*/
#notificationContainer:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  color: transparent;
  border: 10px solid black;
  border-color: transparent transparent #edf2f6;
  margin-top: -20px;
  margin-right: 0;
  right: 50px;
}

#notificationTitle {
  z-index: 1000;
  width: 100%;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  color: var(--color-dark);
}

#notificationFooter {
  background-color: #e9eaed;
  text-align: center;
  font-weight: bold;
  padding: 8px;
  font-size: 12px;
  border-top: 1px solid #dddddd;
}

.notifications {
  overflow-y: auto;
  height: 76vh;
}

.notifaction-box {
  font-family: "Acumin Pro", sans-serif;
  border-radius: 8px;
  background: var(--bg-color-light);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 12px;
  margin-bottom: 12px;
}

.notifaction-box h5 {
  font-family: "Acumin Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 0;
  padding-bottom: 12px;
  color: var(--color-gray);
}

.notifaction-box h4 {
  font-family: "Acumin Pro", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 0;
  padding-bottom: 20px;
  color: var(--color-dark);
}

.notifaction-box:last-child h4 {
  padding-bottom: 0;
}

.notifaction-box .btn-section.notifaction-btn button {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 78.688%;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.formio-component .formio-form .formio-hidden {
  display: none;
}

/*-----------------------------
    10/04/2024 title page
-------------------------------------*/

.m_title_section p {
  text-align: left !important;
  padding-bottom: 24px !important;
  font-family: "Acumin Pro", sans-serif !important;
  color: #000;
}

.m_title_section {
  padding-top: 87px;
  margin-top: 0;
}

.m_title_section ul {
  text-align: left;
  padding-left: 50px;
}

.m_title_section ul li {
  list-style: square;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  font-family: "Acumin Pro", sans-serif;
  color: #000;
}

.m_title_section .btn-section {
  padding-top: 80px;
}

/*-----------------------
  ottimo page  10/04/2024
 ----------------------------*/

.Ottimo_section p {
  text-align: center;
  padding-bottom: 24px;
  font-family: "Acumin Pro", sans-serif;
  margin-bottom: 0;
  font-weight: 400;
  color: #000;
}

/* .Ottimo_section .meditation-yaga-img img {
  width: 300px;
  height: 300px;
  padding: 0 83.156px 16.749px 84.154px;
} */

.Ottimo_section .left-side {
  padding-top: 0 !important;
  margin-top: 0;
}

.Ottimo_section {
  padding: 142px 16px 99px 16px;
}

.Ottimo_section .right-side {
  margin-top: 0;
}

/*---------------------
  account-confermato 10/04/2024
-----------------------------*/

.account_confermato_section .btn-section {
  padding-top: 40px;
}

/*--------------------
 verifica-account 10/04/2024
---------------------------*/

.verifica-account .otp-input-fields {
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.verifica-account .otp-input-fields input {
  height: 40px;
  width: 40px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #000;
  text-align: center;
  outline: none;
  font-size: 16px;
}

.verifica-account .otp-input-fields input::-webkit-outer-spin-button,
.verifica-account .otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.verifica-account .otp-input-fields input[type="number"] {
  -moz-appearance: textfield;
}

.verifica-account .otp-input-fields input:focus {
  border-width: 1px;
  border-color: #000;
  font-size: 16px;
}

.verifica-account .address-detail .form-group {
  padding: 24px 24px;
}

.verifica-account .address-detail-inner {
  padding: 40px 0 40px 0;
}

.link a {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Acumin Pro", sans-serif;
}

.link p {
  color: #435e9b;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Acumin Pro", sans-serif;
  padding-bottom: 0;
}

.link:hover p {
  text-decoration: none;
  /* Remove underline on hover */
}

/* .linkcontent a {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Acumin Pro", sans-serif;
} */

.linkcontent button {
  color: #435e9b;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-family: "Acumin Pro", sans-serif;
  margin-top: 28px;
  border: none;
  background-color: transparent;
}

.linkcontent:hover p {
  text-decoration: none;
  /* Remove underline on hover */
}

/*------------------------------
    upload id 11/04/2024
--------------------------------*/

.id-upoload img {
  width: 130.983px;
  height: 109.011px;
}

.id-upoload .right-side {
  margin-top: 20px;
}

.id-upoload .btn-section {
  padding: 40px 0;
}

.id-upoload select {
  /* background: none; */
  border: 1px solid var(--color-dark);
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 8px 8px;
  color: var(--color-dark);
  border-radius: 8px;
  width: 100%;
}

.id-upoload .file-upload-input {
  /* position: absolute; */
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.id-upoload-two .file-upload-input {
  display: none;
}

.id-upoload-two .file-upload-labels {
  padding: 0px !important;
  color: var(--INDACO-Blu, #435e9b) !important;
  text-align: center;
  font-family: "Acumin Pro";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-decoration-line: underline;
}

.id-upoload-two .file-upload-text {
  font-size: 14px;
}

.id-upoload .image-upload-wrap {
  margin-top: 20px;
  position: relative;
}

.id-upoload .image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.id-upoload .drag-text {
  text-align: center;
  border: 2px dashed #000;
  border-radius: 4px;
}

.id-upoload .drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #15824b;
  padding: 60px 0;
}

.id-upoload .file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.id-upoload .drag-text svg {
  margin-top: -30px;
  height: 80px;
}

/*----------------------
  id upload 02
----------------------------*/

.id-upoload-two .drag-text {
  border: none;
}

.id-upoload-two .drag-text svg {
  display: block;
  height: auto;
  margin: 15px auto;
}

.id-upoload-two label.carica {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

/*----------------------
  registration form
----------------------------*/

.register_title_section .meditation-yaga-section h2 {
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  font-family: "Acumin Pro", sans-serif;
  margin-bottom: 0;
}

.register-detail {
  padding: 33px 32px;
}

.register-field label {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-bottom: 0; */
  color: #000;
}

.register-detail .btn-section {
  margin-top: 20px;
  /* margin-bottom: 20px; */

}

.register-detail .form-group {
  padding: 16px 24px;
  font-size: 14px;
}

.register-detail .form-group:last-child {
  margin-bottom: 0;
}

.register-detail .form-control {
  background: none;
  border: 1px solid #000;
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.827px;
  padding: 8px 8px;
  color: #000;
  border-radius: 8px;
}

.register-sections {
  padding: 40px 0;
  padding-bottom: 14px;
}

/*----------------------
  16/04/2024
----------------------------*/
.drag-text .uploaded_text {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.id-upoload-two .drag-text svg {
  display: block;
  height: auto;
  margin: 15px auto;
}

/***19/04/2024***/

.signin-section .btn-section {
  padding-bottom: 40px;
}

.register-detail.signin-section {
  padding-top: 102px;
  padding-bottom: 100px;
}

/*---------------------
  Desktop device (18/04/2024)
-----------------------------*/

@media only screen and (max-width: 767px) {
  .address-detail-inner.register-form .form-group label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
    padding-bottom: 14px;
  }

  .address-detail-inner.register-form .form-group {
    padding: 16px 24px;
    font-size: 14px;
  }

  .contact-detail h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .contact-detail h5 {
    font-size: 20px;
  }

  .c_modal h2 {
    font-size: 18px !important;
    padding: 0 25px 0 0 !important;
  }

  .c_modal .login_detail_nner {
    padding: 0 0;
  }

  .c_modal .option-box.login_check_box .lns-checkbox span {
    line-height: 22px !important;
    font-size: 16px !important;
    padding-left: 58px !important;
  }

  .c_modal .option-box label {
    width: 100%;
  }

  .c_modal h1 {
    font-size: 24px !important;
  }

  .c_modal p {
    font-size: 16px !important;
  }

  .c_modal .main-accordation .accordion .accordion-item {
    padding: 20px 20px !important;
  }

  .c_modal .main-accordation .accordion button .accordion-title {
    padding: 0 25px 0 0 !important;
  }

  .c_modal .main-accordation .accordion .accordion-item ul {
    padding-left: 18px;
    padding-top: 15px;
  }

  .c_modal .main-accordation .accordion .accordion-item ul li {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  /***18/04/2024***/

  .option-detail .input-box label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
  }

  .footer-section {
    position: sticky;
    bottom: 0;
  }

  .footer-section img {
    height: 12.3vh;
  }

  .address-detail-inner.register-form .form-group label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
    padding-bottom: 14px;
  }

  .address-detail-inner.register-form .form-group {
    padding: 16px 24px;
    font-size: 14px;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0;
    background: transparent;
    box-shadow: none;
    text-align: left;
    margin-top: 17px;
  }

  .left-side img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }

  .left-side .one h3.mobile {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    padding-top: 10px;
    color: var(--color-dark);
    word-break: break-all;
    display: block;
  }

  .left-side h2 {
    font-size: 32px;
    line-height: 28px;
    padding-top: 0;
    text-align: left;
    margin-bottom: 0;
  }

  .left-side .one h3 br {
    display: none;
  }

  .address-detail {
    max-width: 100%;
  }

  .right-side {
    margin-top: 15px;
  }

  .address-detail .option-box {
    margin-bottom: 0;
    align-items: start;
  }

  .form-check .form-check-input {
    margin-left: -25px !important;
  }

  .mobile {
    display: block !important;
  }

  .desktop {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1099px) {
  .option-detail .input-box label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
  }

  .id-upoload img {
    width: 400px;
    height: 400px;
  }

  .id-upoload {
    align-items: start !important;
  }

  .account_confermato_section {
    align-items: normal !important;
  }

  .faq-img img {
    width: 75%;
  }

  .faq-section .row {
    align-items: normal !important;
  }

  .faq-section .left-side {
    padding: 10px 0 0 0;
  }

  .address-detail-inner.register-form .form-group label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
    padding-bottom: 14px;
  }

  .address-detail-inner.register-form .form-group {
    padding: 16px 24px;
    font-size: 14px;
  }

  /***18/06/2024***/

  .slider-section .carousel.slide {
    border-radius: 20px;
    background: var(--bg-color-light);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 50px 60px 85px 60px;
    max-width: 995px;
    margin: 0 auto;
    position: relative;
  }

  .slider-section .carousel-control-prev-icon {
    display: none;
  }

  .slider-section .carousel-control-next-icon {
    display: none;
  }

  .slider-section .carousel-indicators {
    margin-bottom: 0;
  }

  .slider-section .carousel-indicators button {
    width: 16px;
    height: 16px;
    color: #c5c5c5;
    background-color: #c5c5c5;
    border-radius: 100px;
    border: none;
    margin: 0 5px;
    margin-bottom: 15px;
    opacity: 9;
  }

  .slider-section .carousel-indicators button.active {
    opacity: 1;
    color: #b4e4ba;
    background-color: #b4e4ba;
  }

  .slider-section .card-block {
    flex-wrap: nowrap;
  }

  .main-header .nav-links {
    display: flex !important;
  }

  .hamburger-react {
    display: none;
  }

  .footer-section {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  .contact_d .contact-img img {
    width: 90%;
  }

  .contact_d {
    flex-direction: row-reverse;
  }

  .contact_d .right-side {
    margin: 0 30px;
  }

  .main-header button.solid-btn {
    padding: 12px 20px 12px 20px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1100px) {
  .option-box.login_check_box {
    display: flex;
    justify-content: center;
    gap: 13px;
  }

  .cosa-section {
    padding-top: 0px;
  }

  .option-detail .input-box label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
  }

  .option-detail {
    padding: 50px 0;
    min-height: 100%;
    position: relative;
  }

  .id-upoload img {
    width: 200px;
    height: 200px;
  }

  .id-upoload {
    align-items: start !important;
  }

  .Ottimo_section p {
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    font-family: "Acumin Pro", sans-serif;
    color: var(--color-dark);
    margin-bottom: 0;
  }

  .account_confermato_section {
    align-items: normal !important;
  }

  .Ottimo_section {
    padding: 30px 0 0 0;
  }

  .faq-img img {
    width: 400px;
    height: 400px;
  }

  .faq-section .row {
    align-items: center !important;
  }

  .faq-section .left-side {
    padding: 10px 0 0 0;
  }

  .id-upoload .left-side {
    padding: 10px 0 0 0;
  }

  .drag-text h6 {
    font-size: 20px;
  }

  .id-upoload-two a.carica {
    font-weight: 500;
    font-size: 18px;
  }

  .id-upoload .right-side {
    margin-top: 0;
  }

  .Ottimo_section .meditation-yaga-img img {
    width: 100%;
    height: auto;
  }

  .question_detail h3 {
    font-size: 22px;
  }

  .address-detail-inner.register-form .form-group label {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    color: #000;
    padding-bottom: 14px;
  }

  .address-detail-inner.register-form .form-group {
    padding: 16px 24px;
    font-size: 14px;
  }

  .address-detail .input-box {
    padding: 0 8px 0 8px;
  }

  /***18/06/2024***/

  .slider-section .carousel.slide {
    border-radius: 20px;
    background: var(--bg-color-light);
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 50px 60px 85px 60px;
    max-width: 995px;
    height: 400px;
    margin: 0 auto;
    position: relative;
  }

  .slider-section .carousel-control-prev-icon {
    display: none;
  }

  .slider-section .carousel-control-next-icon {
    display: none;
  }

  .slider-section .carousel-indicators {
    margin-bottom: 0;
  }

  .slider-section .carousel-indicators button {
    width: 16px;
    height: 16px;
    color: #c5c5c5;
    background-color: #c5c5c5;
    border-radius: 100px;
    border: none;
    margin: 0 5px;
    margin-bottom: 15px;
    opacity: 9;
  }

  .slider-section .carousel-indicators button.active {
    opacity: 1;
    color: #b4e4ba;
    background-color: #b4e4ba;
  }

  .slider-section .card-block {
    flex-wrap: nowrap;
  }

  .main-header .nav-links {
    display: flex !important;
  }

  .hamburger-react {
    display: none;
  }

  .footer-section {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  /* .right-side.address-detail.address-label .label-box {
    padding: 0 8px 32px 8px;
  } */

  .contact_d .contact-img img {
    width: 400px;
    height: 400px;
  }

  .contact_d {
    flex-direction: row-reverse;
  }

  .contact_d .right-side {
    margin: 0 30px;
  }

  .main-header button.solid-btn {
    padding: 12px 20px 12px 20px;
    font-size: 16px;
  }

  .register-detail {
    height: 1100px;
  }

  .meditation-yaga-img img {
    width: 400px;
    height: 400px;
  }

  /***24/06/2024***/
  .btn-section.btn-desktop {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: auto;
    z-index: 9;
    margin: 0 auto;
    bottom: 20px;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  /* .right-side.customformcss {
    height: 670px;
    overflow-y: auto;
  } */

  .prossima {
    margin: 30px auto 0 auto;
  }

  .form-check .form-check-input {
    margin-left: -30px !important;
    margin-top: -2px;
  }
}

.quanti fieldset legend {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  margin: 0px;
}

.quanti fieldset .fieldset-body {
  display: flex;
  justify-content: center;
}

.quanti .formio-component-anni_vissuto {
  width: 160px;
  padding: 0px !important;
  margin: 0px !important;
}

.quanti .formio-component-mesi_vissuto {
  width: 160px;
  padding: 0px !important;
  margin: 0px !important;
}

.quanti .form-group {
  padding: 0px !important;
}

.birth-date {
  margin-top: 8px;
}

.ti-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.formio-component.kg-content .card .card-body .form-group {
  padding: 0 !important;
  margin: 0 !important;
}

.formio-component .card .card-body .form-group .peso {
  padding: 0 !important;
  margin: 0 !important;
}

.formio-component .card .card-body .form-group .nonso {
  padding: 5px 0 0 0 !important;
  margin: 0 !important;
}

.formio-component .card .card-body .form-group .nonso label {
  padding-bottom: 0;
}

.formio-component .card .card-body .form-group .peso {
  padding: 0 !important;
  margin: 0 !important;
}

.formio-component .card .card-body .form-group .peso label {
  padding-bottom: 0;
}

.formio-component .card .card-body .form-group .aletzza {
  padding: 5px 0 0 0 !important;
  margin: 0 !important;
}

.formio-component .card .card-body .form-group .aletzza label {
  padding-bottom: 0;
}

.ti-preghiamo-title {
  color: var(--color-dark);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  background: transparent;
  margin-bottom: 0;
  border: none;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full screen height */
}

.loader {
  border: 16px solid #d4cfcf;
  /* Light grey */
  border-top: 16px solid #435e9b;
  /* Blue */
  border-radius: 50%;
  left: 48%;
  top: 38%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Data-di-nascita-content {
  margin-top: 0px !important;
}

.dati_Di_Contatto {
  margin-top: 50px;
}

.indicare {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: -5px;
}

.ti-preghiamo-name {
  margin-top: 40px;
  margin-bottom: -5px;
}

/***Changes Of 30/05/2024***/
.prev-questionaries {
  text-align: center;
  padding: 16px;
}

.prev-questionaries button {
  color: var(--INDACO-Blu, #435e9b) !important;
  font-family: "Acumin Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration-line: underline !important;
  border: none;
  line-height: 22.45px;
  text-align: left;
  background-color: transparent;
}

.quantis fieldset legend {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;
  margin: 0px;
}

.quantis fieldset .fieldset-body {
  display: flex;
  justify-content: center;
}

.quantis .anni_vivi {
  width: 150px;
  padding: 0px !important;
  box-shadow: none;
  margin: 3px !important;
}

.quantis {
  box-shadow: none;
}

.Dati-anagrafici {
  margin-top: 50px;
}

/***19/06/2024***/

.h-24 {
  height: 24px;
}

.c_modal h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-family: "Acumin Pro", sans-serif;
}

.c_modal h2 {
  font-family: "Acumin Pro", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 0;
  padding: 0 67px 0 0 !important;
}

.c_modal h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-family: "Acumin Pro", sans-serif;
}

.c_modal p {
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-align: left;
  margin-top: 0 !important;
}

.c_modal p.small {
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
}

.c_modal #accordion-button-1 {
  padding: 0 0;
}

.c_modal ul li {
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 0 0 15px;
  color: #000;
  list-style: disc;
}

.c_modal .modal-dialog.modal-dialog-scrollable {
  max-width: 800px;
}

.c_modal .main-accordation .accordion button[aria-expanded="true"]+.accordion-content {
  max-height: inherit;
}

.c_modal .main-accordation .accordion button .icon::after {
  top: -25px;
  left: -27px;
}

.c_modal .main-accordation .accordion .accordion-item {
  padding: 24px 24px;
}

.c_modal .option-box.login_check_box .lns-checkbox span::before {
  position: absolute;
  top: 15px;
  left: 10px;
  display: none;
}

.c_modal .option-box.login_check_box .lns-checkbox span {
  width: 100%;
  height: 100%;
  font-family: "Acumin Pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  gap: 8px;
  padding: 15px 15px;
  align-items: start;
  text-align: left;
  padding-left: 63px !important;
}

.c_modal .option-box {
  display: block;
}

.c_modal .option-box.login_check_box .lns-checkbox input[type="checkbox"]:checked+span::after {
  top: 22px;
  left: 24px;
  width: 12px;
  height: 20px;
}

.c_modal .option-box .lns-checkbox input[type="radio"] {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.c_modal .form__radio-input {
  display: none;
}

.c_modal .form__radio-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.c_modal .form__radio-button {
  height: 35px;
  width: 35px;
  border: 5px solid #55c57a;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  /* transform: translateY(-2px); */
  z-index: 99;
  left: auto;
  margin-left: 10px;
  right: auto;
}

.c_modal .form__radio-button::after {
  content: "";
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transition: opacity 0.1s;
  transform: translate(-50%, -50%);
  background-color: #55c57a;
  opacity: 0;
}

.c_modal .form__radio-input:checked+.form__radio-label .form__radio-button::after {
  opacity: 1;
}

.password-input-container {
  position: relative;
}

.question-label {
  background-color: #fff;
  margin-bottom: -20px;
}

.question-label p {
  padding: 20px 20px 0 20px;
}

.question-leftlable {
  box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
}

.questionLabel {
  box-shadow: 1px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: 768px) {
  .active-link {
    font-weight: 900 !important;
  }
}

@media (min-width: 768px) {
  header.header-light {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    background: var(--bg-color-light);
    height: 60px;
    padding: 0 6px;
  }

  .main-header a {
    text-decoration: none;
    text-transform: capitalize;
    color: var(--color-white);
  }

  .dark-navname a {
    color: #435e9b;
  }

  .additional-language-selection li span {
    color: #435e9b !important;
  }
}

@media (min-width: 1100px) {
  .desktop-details label {
    font-size: 18px !important;
    font-weight: 700 !important;
    padding-bottom: 8px !important;
  }

  .invalid-feedback .error {
    margin-bottom: 0px !important;
    position: inherit;
    margin-top: 24px;
  }

  .faq-section .faq-img {
    position: fixed;
    top: 40%;
    left: -25%;
    width: 100%;
    z-index: 10;
    /* Ensure it appears above other content */
  }

  .faq-section .container {
    margin-top: 50px;
    /* Adjust this value to avoid content overlap */
  }

  .grazie-img {
    width: 280px !important;
    height: 280px !important;
  }

  .desktop-details input {
    margin-top: 0 !important;
    font-size: 20px !important;
    font-weight: 300 !important;
  }

  .desktop-details .form-group {
    padding: 16px 16px 24px 16px !important;
  }

  .register-sections.register-scroll {
    height: 850px;
    overflow-y: auto;
  }

  .main_question_box .row {
    flex-direction: column;
    align-items: center;
  }

  .privacycheck.option-box.login_check_box .lns-checkbox span {
    margin: 0;
    font-size: 14px;
    line-height: 15px !important;
    text-align: left;
  }
}

.title-que p {
  color: var(--color-dark);
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  background: transparent;
  margin-bottom: 0;
  border: none;
  margin-top: 20px;
  padding-bottom: 0px;
  margin-bottom: -5px;
}

.title-que-label p {
  color: var(--color-dark);
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
  background: #ffffff;
  border: none;
  margin-top: 20px;
  padding: 15px 15px 0px 15px;
}

a[href="no-link"] {
  text-decoration: underline;
  /* Add underline */
  cursor: default;
  /* Default cursor */
  pointer-events: none;
  /* Disable click */
  color: #000;
}

.questions-label p {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 0 12px 0;
  margin-bottom: -16px;
  color: #000;
}

.questions-label {
  background-color: #fff;
  margin-bottom: -20px;
}

.Durante-questions-label p {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 16px 24px;
  margin-bottom: -16px;
  color: #000;
}

.Durante-questions-label {
  background-color: #fff;
  margin-bottom: -20px;
}

.questions-label p {
  padding: 20px 20px 0 20px;
}

.title-ques p {
  color: var(--color-dark);
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  background: transparent;
  margin-bottom: 0;
  border: none;
  margin-top: 20px;
  padding: 12px 10px 0px 10px;
  margin-bottom: -5px;
}

/* Hide spinners for Chrome, Safari, Edge, and Opera */
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (min-width: 1440px) {
  .left-side-content {
    width: 420px;
    height: 480px;
    position: fixed;
    top: 27% !important;
  }
}

@media screen and (min-width: 1024px) {
  .left-side-content {
    position: sticky;
    top: 75px !important;
  }

  .other-question {
    margin-bottom: 140px;
  }

  .documenti-section p {
    text-align: center !important;
  }

  .documenti-ul {
    position: absolute;
    left: 25%;
  }

  .formio-component label {
    font-weight: 400;
  }

  .prev-questionaries {
    padding-bottom: 50px;
  }

  .Durante-questions-label p {
    margin-bottom: 19px;
    font-size: 24px !important;
    padding: 15px;
  }

  .Dati-anagrafici {
    background: #0000;
    border: none;
    color: #000;
    color: var(--color-dark);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: -5px;
    margin-top: 20px;
    padding-bottom: 0;
  }

  .dati_Di_Contatto {
    background: #0000;
    border: none;
    color: #000;
    color: var(--color-dark);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: -5px;
    margin-top: 20px;
    padding-bottom: 0;
  }
}

.salva-button {
  margin-bottom: 20px !important;
}

.ladyimg {
  width: 143.25px;
  height: 200px;
  margin-bottom: 25px;
}

.modals-section {
  position: relative;
  border-radius: 20px;
  border: 2px solid #435E9B;
  padding: 40px 25px;
  margin: -20px;
  text-align: center;
}

.modal-content h1 {
  font-family: Acumin VF;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: 18px;
}

.modal-content p {
  font-family: Acumin VF;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
}

.prossima-btn {
  margin-bottom: 10px !important;
}

.prossima-btn button {
  background-color: #435e9b !important;
}

.modal-content {
  padding: 25px 20px;
  border-radius: 20px;
  border: 2px solid #435E9B;
  top: 0;
}