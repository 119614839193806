* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .h-30 {
    height: 30px;
  }
  
  body .pdf {
    /* font-family: "Roboto", sans-serif; */
    font-family: 'Roboto Serif', serif;
  }
  
  .text-center {
    text-align: center;
  }
  
  .blue {
    color: #4573c4;
  }
  
  ul li {
    font-size: 14px;
    text-align: justify;
    line-height: 28px;
    color: #000;
  }
  
  /*-------------------
     css start
    ------------------------*/
  
  .main_section {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 30px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    border: 1px solid #ccc;
  }
  
  .main_section .dep {
    width: 220px;
    display: block;
  }
  
  .main_section .indaco {
    width: 300px;
    margin: 0 auto;
    display: block;
    padding: 15px 30px;
  }
  
  .main_section h1 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #435E9B;
    text-transform: uppercase;
  }

  .main_section h2 {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-transform: uppercase;
    padding-bottom: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .main_section h6 {
    font-size: 10px;
    color: #000000;
    line-height: 12px;
  }
  .main_section h5 {
    font-size: 10px;
    color: #000000;
    line-height: 12px;
    padding-bottom: 5px;
  }
  
  .main_section .small {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }
  
  .main_section p {
    font-size: 13px;
    text-align: justify;
    line-height: 16px;
    color: #000;
  }
  
 
  .rules {
    list-style: none;
    padding-top: 5px;
  }
  
  .rules li {
    font-size: 13px;
    text-align: justify;
    line-height: 28px;
    color: #000;
  }
  

  ul.consenso {
    padding: 0 15px;
  }
  
  /***checkbox***/
  
  .pdfCheckbox #checkboxes {
    background-color: #fff;
    min-height: 200px;
    padding: 0;
    margin-top: 0;
  }
  
  .pdfCheckbox .checkbox {
    margin: 0 15px 20px 35px;
  }
  
  .pdfCheckbox .checkbox label {
    font-size: 14px;
    text-align: justify;
    line-height: 28px;
    color: #000;
    padding-left: 15px;
    display: block;
  }
  
  .pdfCheckbox .c-checkbox span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: -30px;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 2px solid #8d8d8d;
    text-align: center;
    margin-right: 4px;
  }
  
  .pdfCheckbox .c-checkbox span:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 12px;
    line-height: 13px;
    vertical-align: middle;
    font-weight: 300;
  }
  
  .pdfCheckbox .c-checkbox input[type='checkbox'] {
    position: absolute;
    margin: 4px 0 0;
    opacity: 0;
  }
  
  .pdfCheckbox .c-checkbox input[type='checkbox']:checked {
    color: #fff;
    transition: color 0.3s ease-out;
  }
  
  .pdfCheckbox .c-checkbox input[type='checkbox']:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec;
  }
  
  .pdfCheckbox .c-checkbox input[type='checkbox']:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3s ease-out;
  }
  
  .footer_s img {
    width: 100%;
  }
  